import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { MdDashboard, MdLogout, MdPending, MdQuestionMark, MdVideoCall } from 'react-icons/md'
import { FaUsers } from 'react-icons/fa'

function AdminSidebar() {
  const navigation = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [userData, setData] = useState({});

  const logOut = () =>{
    localStorage.removeItem("userData");
    setTimeout(() => {
      navigation('/login')
    }, 300);

  } 
  
  useEffect(()=>{
    var user = JSON.parse( localStorage.getItem('userData') )
    if(user){
      setData(user)
   // console.log(user)
    }else{
      navigation('/login')
    }
    
  },[])
  return (
    // <div style={{ display: 'flex', height: '100%', minHeight: '800px' }}>
      <Sidebar collapsed={collapsed} transitionDuration={1000}
        backgroundColor='rgb(0 131 207 / 97%)'>
        <div className='user-details'>
          <img src={require('../../../user.png')} alt='' className='profile-img' />
          <p className='user-text'>Name: {userData.fname}
            <br />
            Time: {new Date().getHours() + ':' + new Date().getMinutes()} <br />
          </p>
        </div>
        <Menu>
          <MenuItem
            icon={<MdDashboard />}
            component={<Link to='/admin/dashboard' />}> Dashboard</MenuItem>
            {userData.lavel ==='1' && 
            <>
          <MenuItem
            icon={<FaUsers />}
            component={<Link to='/admin/users' />} > Users</MenuItem>
          <MenuItem
            icon={<MdPending />}
            component={<Link to='/admin/users-pending' />} > Pending request list</MenuItem>
          
          <MenuItem
            icon={<MdQuestionMark />}
            component={<Link to='/admin/question-master' />}
          > Question master</MenuItem>
          </>}
          {userData.lavel ==='2' && 
            <>
          <MenuItem
            icon={<MdVideoCall />}
            component={<Link to='/admin/video' />}
          > Video </MenuItem>
          </>}
          <MenuItem
            icon={<MdLogout />}
            component={<Link onClick={logOut} />}
          > Logout</MenuItem>
        </Menu>
      </Sidebar>
      
  )
}

export default AdminSidebar