import React, { useEffect, useState, useRef } from 'react'
import loginimg from './login.jpg'
import { Alert, Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Spinner } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { MdAccountBox, MdDrafts, MdLogin, MdSend } from "react-icons/md";
import { FaCalculator, FaCalendar, FaEye, FaPlusCircle, FaTrash, FaUser, FaUserLock } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from 'react-redux';
import {
    setError, setSubmited, setFname, setLname, setEmail, setPhone, sendLnk, setFathername, setDob,
    setAddressprfExp, setIdproofExp
} from './app/features/sendLinkSlice';
import { FaRepeat } from 'react-icons/fa6';
import axios from 'axios';
import PhotoUploadOrCapture from './PhotoUploadOrCapture';
import OtpInput from 'react-otp-input';



function Login() {
    const location = useLocation();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [checkOtp, setOtp] = useState(null)
    const [EmailLoading, setEmailLoading] = useState(false)
    const [Emailverifyed, setEmailverifyed] = useState(false)
    const [otp, setOotp] = useState('');

    const [verifyOtpD, setverifyOtp] = useState(null)
    const [submitDiseable, setsubmitDiseable] = useState(true)
    const { error, loading, submited, fname, lname, fathername, dob, email, phone, id_proof_exp, adress_prf_exp } = useSelector((state) => state.sendKyc);
    const [idProofFile, setidProofFile] = useState(null);
    const [idProobkfFile, setidProofbkFile] = useState(null);
    const [profilephotoFile, setProfilephotoFile] = useState(null);
    const [image, setImage] = useState(null);
    const [addressFile, setaddressFile] = useState(null);
    const [uploadDatas, setUploads] = useState(false);

    const [govtDetails, setGovtDetails] = useState([]);
    const [govtDetailsList, setGovtDetailsList] = useState([]);


    const onChange = (value) => {
        console.log("Captcha value:", value);
    }
    const uploadGovtId = async () => {
        setUploads(true)
        const formData1 = new FormData();
        formData1.append('email', email);
        formData1.append('file_type', govtDetails.file_type);
        formData1.append('id_exp_date', govtDetails.id_exp_date);
        formData1.append('upload_b_sde', govtDetails.upload_b_sde);
        formData1.append('upload_f_sde', govtDetails.upload_f_sde);
        try {
            const response = await axios.post('https://nuggettrap.com/api/ekyc/upload-id-proof.php', formData1, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response)
            console.log(response.data.result)
            setUploads(false)
            if (!response.data.error) {
                setGovtDetailsList((prev) => [...prev, response.data.result])
                setGovtDetails({ file_type: '', id_exp_date: '', upload_b_sde: '', upload_f_sde: '' });
            }
        } catch (error) {
            setUploads(false)
            // setUploadStatus('File upload failed');
        }
    }
    const uploadData = async () => {

        const formData = new FormData();
        formData.append('email', email);
        formData.append('profile_photo', profilephotoFile);
        formData.append('address_proof', addressFile);
        formData.append('id_proof_exp', id_proof_exp);
        formData.append('adress_prf_exp', adress_prf_exp);
        try {
            const response = await axios.post('https://nuggettrap.com/api/ekyc/upload-data.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

        } catch (error) {
            // setUploadStatus('File upload failed');
        }
    }

    const sendEmial = () => {
        if (email === '') return dispatch(setError('Please enter your email!'))
        setEmailLoading(true);
        dispatch(setError(null));
        fetch('https://nuggettrap.com/api/ekyc/send-email.php', {
            method: 'POST',
            body: JSON.stringify({ email: email })
        })
            .then((res) => res.json())
            .then((result) => {
                setEmailLoading(false);
                console.log(result)
                if (!result.error) {
                    setOtp(result.otp)
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                } else {
                    alert(result.result);
                    dispatch(setEmail(''));
                }

                // console.log(result)
            })
    }
    const handalPushDetails = () => {
        if (govtDetailsList.length <= 2 && govtDetails.file_type && govtDetails.id_exp_date) {
            if (govtDetails.file_type != '' && govtDetails.id_exp_date != '') {
                //setGovtDetailsList((prev) => [...prev, govtDetails])
                uploadGovtId();
                console.log(govtDetails)
            }
        }
    }
    const handalDelete = async (dellist) => {

        var allList = govtDetailsList.filter(list => list.id != dellist.id)
        setGovtDetailsList(allList)
        const data = { id: dellist.id };
        const resDat = await axios.post(`https://nuggettrap.com/api/ekyc/delete-id-proof.php?id=${dellist.id}`);

    }
    const verifyOtp = () => {
        if (email === '') return dispatch(setError('Please enter your email!'))
        if (otp === '') return dispatch(setError('Please enter OTP!'));

        dispatch(setError(null));
        if (otp == checkOtp) {
            setsubmitDiseable(false)
            setEmailverifyed(true)
        } else {
            return dispatch(setError('Please enter Valid OTP!'));
        }
    }
    const handalSubmit = (e) => {
        e.preventDefault();
        //uploadData();
        if (fname === '') return dispatch(setError('Please enter your name!'));
        if (email === '') return dispatch(setError('Please enter your email!'))
        if (phone === '') return dispatch(setError('Please nter your mobile number!'))

        dispatch(setError(null));
        const data = { fname: fname, lname: lname, email: email, phone: phone, fathername: fathername, dob: dob };
        uploadData();
        dispatch(sendLnk(data))
        dispatch(setSubmited(true))
        dispatch(setFname(''));
        dispatch(setLname(''));
        dispatch(setEmail(''));
        dispatch(setPhone(''));
        dispatch(setDob(''));
        dispatch(setFathername(''));
    }
    const handalPhoto = (e) => {
        console.log(e)
        setProfilephotoFile(e)
    }
    useEffect(() => {
        console.log(location)
    }, [location])
    useEffect(() => {
        console.log(govtDetailsList)
    }, [govtDetailsList])
    return (
        <div className='login-main'>
            <Row className='row-login'>
                <Col md={2}>
                    {/* <img src={loginimg} style={{ width: '100%' }} alt='ekyc login' /> */}
                </Col>
                <Col md={8} >
                    <div className='login-box'>
                        <h2 className='text-center'>Send EKYC link</h2>
                        <p>Go to <Link to='/login'>login page</Link> </p>
                        <form method='post' className='log-form' onSubmit={handalSubmit}>
                            {location.state &&
                                <>
                                    {location.state.message &&
                                        <Alert color='danger'>{location.state.message}</Alert>}
                                </>
                            }
                            {error && <p className='text-danger'>{error}</p>}
                            {submited && <p className='text-success'>Please check your email</p>}
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText>
                                                <FaUser color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input placeholder='Enter your first name' value={fname}
                                                onChange={(e) => { dispatch(setFname(e.target.value)) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText>
                                                <FaUser color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input placeholder='Enter your last name' value={lname}
                                                onChange={(e) => { dispatch(setLname(e.target.value)) }}
                                            />
                                        </InputGroup>
                                    </FormGroup></Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label className='dob-label'>Date of birth</Label>
                                    <FormGroup>
                                        <InputGroup>

                                            <InputGroupText>
                                                <FaCalendar color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input value={dob}
                                                type='date' onChange={(e) => { dispatch(setDob(e.target.value)) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Phone</Label>
                                        <PhoneInput
                                            country={'us'}
                                            style={{ width: '100%' }}
                                            value={phone}
                                            onChange={phone => dispatch(setPhone(phone))}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText>
                                                <MdDrafts color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input placeholder='Enter your Email' value={email}
                                                onChange={(e) => { dispatch(setEmail(e.target.value)) }} />
                                            <InputGroupText>
                                                <MdSend color='#2a278e' size={20} className='send-button'
                                                    onClick={sendEmial} />
                                            </InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                    <span>Click <MdSend /> Send button to get OTP</span>
                                </Col>
                                <Col md={3} className='otp-box'>
                                    {/* <Input placeholder='Enter OTP'
                                        onChange={(e) => { setverifyOtp(e.target.value) }}
                                        ref={inputRef}
                                    /> */}
                                    <OtpInput
                                        value={otp}
                                        onChange={setOotp}
                                        numInputs={4} className='form-control'
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ButtonGroup>
                                        {EmailLoading ? (
                                            <Spinner color="info" >
                                                Loading...
                                            </Spinner>
                                        ) : (
                                            <>{
                                                Emailverifyed ? (
                                                    <Button color='success' type='button' >Verifyed</Button>
                                                ) : (
                                                    <Button color='danger' type='button' onClick={verifyOtp}>Verify</Button>
                                                )
                                            }
                                                {/* <Button color='danger' type='button' onClick={verifyOtp}>Verify</Button> */}

                                                <Button color="warning" type='button' onClick={sendEmial}>
                                                    <FaRepeat />
                                                </Button>
                                            </>
                                        )}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={8}>
                                    {image &&
                                        <img src={image} alt="photo" className='profile-img-r' />
                                    }
                                </Col>
                                <Col md={4}>

                                    {/* <Label>Upload your photo</Label>
                                    <Input type='file'
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setImage(reader.result);
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                            setProfilephotoFile(e.target.files[0])

                                        }} /> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <PhotoUploadOrCapture getPhoto={handalPhoto} />
                                </Col>
                            </Row>
                            <hr />
                            {/* <Input type='file' onChange={(e) => { setidProofFile(e.target.files[0]) }} /> */}
                            {/* <Input type='file' onChange={(e) => { setidProofbkFile(e.target.files[0]) }} /> */}
                            <div className='custom-row'>
                                <div>
                                    <Label>Id Proof type</Label>
                                    <Input type='select' value={govtDetails.file_type}
                                        onChange={(e) => { setGovtDetails((prev) => ({ ...prev, file_type: e.target.value })) }}>
                                        <option value=''>Select option</option>
                                        <option value='govt_id'>Govt Id</option>
                                        <option value='drivers_license'>Drivers License</option>
                                        <option value='passport'>Passport</option>
                                    </Input>
                                </div>
                                <div className='uplod-div'>
                                    <Label> Upload Front Side </Label>
                                    <Input type='file'
                                        onChange={(e) => { setGovtDetails((prev) => ({ ...prev, upload_f_sde: e.target.files[0] })) }} />
                                </div>
                                <div className='uplod-div'>
                                    <FormGroup>
                                        <Label>Upload Back Side </Label>
                                        <Input type='file'
                                            onChange={(e) => { setGovtDetails((prev) => ({ ...prev, upload_b_sde: e.target.files[0] })) }} />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup>
                                        <Label>Id proof expiry date </Label>
                                        <InputGroup>
                                            <InputGroupText>
                                                <FaCalendar color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input value={govtDetails.id_exp_date} type='date'
                                                onChange={(e) => { setGovtDetails((prev) => ({ ...prev, id_exp_date: e.target.value, id: Date.now() })) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className='div-button'>
                                    {uploadDatas ? (
                                        <Spinner color="info" >
                                            Loading...
                                        </Spinner>
                                    ) : (
                                        <Button type='button' color='primary' onClick={handalPushDetails}>
                                            <FaPlusCircle />
                                        </Button>
                                    )
                                    }
                                </div>
                            </div>
                            {govtDetailsList.length > 0 &&
                                <>
                                    {govtDetailsList.map((list, i) => (
                                        <div className='custom-row' key={i}>
                                            <div>
                                                <p>{list.id_proof_file_type} </p>
                                            </div>
                                            <div>
                                                <p><img src={list.id_proof_front} alt='' style={{ height: '50px' }} />  </p>
                                            </div>
                                            <div>
                                                <p><img src={list.id_proof_back} alt='' style={{ height: '50px' }} /> </p>
                                            </div>
                                            <div>
                                                <p>{list.id_proof_exp_date} </p>
                                            </div>
                                            <div className='div-button_0'>
                                                <Button type='button' color='danger' onClick={() => { handalDelete(list) }}>
                                                    <FaTrash />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                            <Row>
                                <Col md={6}>
                                    <Label>Upload Address Proof</Label>
                                    <Input type='file'
                                        onChange={(e) => { setaddressFile(e.target.files[0]) }} />
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Address proof expiry date </Label>
                                        <InputGroup>
                                            <InputGroupText>
                                                <FaCalendar color='#2a278e' size={20} />
                                            </InputGroupText>
                                            <Input value={adress_prf_exp}
                                                type='date' onChange={(e) => { dispatch(setAddressprfExp(e.target.value)) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* 6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O
 */}
                            <ReCAPTCHA
                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                theme='dark'
                                onChange={onChange}
                            />
                            <FormGroup>

                            </FormGroup>
                            <FormGroup>
                                {/*  disabled={submitDiseable} */}
                                <Button type='submit' color='primary' block
                                    disabled={submitDiseable}
                                >
                                    Send link
                                    <MdSend color='#fff' size={20} />
                                </Button>
                            </FormGroup>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login