import React,{ useEffect, useState } from 'react';
import { FormGroup,Label,Input } from 'reactstrap';


function Citys(props) {
    const [cityList, setCityList ] = useState([]);
    const [selectCity, setSelctCity ] = useState('');

    useEffect(()=>{
        props.selectCity(selectCity)
    },[selectCity])
    useEffect(()=>{
        if(cityList.length ==0){
            fetch(`https://nuggettrap.com/api/crm/city-list.php?state_id=${props.selectedStated}`)
            .then((res)=>res.json())
            .then((result)=>{
                setCityList(result.result)
            })
        }
    },[props.selectedStated])
  return (
    <div>
        <FormGroup>
            <Label>City</Label>
            <Input name="city" type="select" onChange={(e)=>{ setSelctCity(e.target.value)}}>
                <option>Select city</option>
                {cityList.length>0 &&
                <>
                {cityList.map((city)=>(
                    <option key={city.id} value={city.id}
                    >{city.name}</option>
                ))}
                </>
                 }
            </Input>
        </FormGroup>
    </div>
  )
}

export default Citys
