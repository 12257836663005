import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap'
import {  } from 'react-icons/md'
import { FaBell } from 'react-icons/fa'

function PendingUserList() {
    const [userList, setUserList] = useState([]);
    const sendReminder = (email,name) =>{
        fetch('https://nuggettrap.com/api/ekyc/send-reminder.php?email='+email+'&fname='+name, {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((result) => {
                ///setUserList(result.result)
                alert('email send successfully')
            })
    }

    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/get-pending-users.php', {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((result) => {
                setUserList(result.result)
            })
    }, [])
  return (
    <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />

                <div style={{ display: 'block' }}>
                    <Card>
                        <CardHeader>
                            <h3> Pending request list</h3>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Full Name</th>
                                        <th>Email </th>
                                        <th>Contact</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.length > 0 &&
                                        <>
                                            {userList.map((user, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1} </td>
                                                    <td>{user.fname} </td>
                                                    <td>{user.email} </td>
                                                    <td>{user.phone} </td>
                                                    <td>
                                                       <Button color='warning' 
                                                       onClick={()=>{sendReminder(user.email, user.fname)}}>
                                                        <FaBell />
                                                            Send reminder
                                                       </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </div>

        </div>
  )
}

export default PendingUserList