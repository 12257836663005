import React, { useEffect, useState } from 'react'
import { FaEye, FaGlobeAsia, FaMap, FaMapMarked, FaMapPin, FaMapSigns, FaSave, FaSign, FaUser } from 'react-icons/fa'
import { MdAccountCircle, MdAddLocation, MdAddToDrive, MdFax, MdFileUpload, MdMail, MdPersonAdd, MdPhoneIphone, MdSaveAs, MdSmartphone } from 'react-icons/md'
import { Alert, Button, Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Progress, Row, Table } from 'reactstrap'
import signUp from './sign-up.jpg'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import { json, useNavigate, useParams } from 'react-router-dom'
import kycImg from './reg.jpg';
import Country from './lib/Country'
import States from './lib/States'
import Citys from './lib/Citys'
import axios from 'axios'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function Registrion() {
    const { key } = useParams();
    const navigation = useNavigate()
    const [linkData, setLinkdata] = useState({});
    const [error, setError] = useState(null);
    const [country, setCountry] = useState(null)
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [passerr, setpasserror] = useState(null);

    const [govtfile, setGovfile] = useState(null);
    const [govtfileBack, setGovfileBack] = useState(null);

    const [addressfile, setAddressfile] = useState(null);
    const [addressfileBack, setAddressfileBack] = useState(null);

    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoBack, setProfilePhotoBack] = useState(null);

    const [progressNow, setProgressNow] = useState(0)
    const [progressno2, setprogressno2] = useState(0)
    const [progressno3, setprogressno3] = useState(0)

    const saveDraft = () => {
        const data = new FormData();
        data.append('alldata', JSON.stringify(linkData));
        axios.post('https://nuggettrap.com/api/ekyc/save-draft.php', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(() => {
               // navigation('/')
            })
    }

    const saveUtility = async () => {
        const fdata = new FormData();
        fdata.append('profilePhoto', profilePhoto);
        fdata.append('profilePhotoBack', profilePhotoBack);
        fdata.append('key_id', key);
        const res = await axios.post('https://nuggettrap.com/api/ekyc/upload-file.php', fdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        setInterval(() => {
            setprogressno3((pre) => pre + 1)
        }, 150);
    }
    const saveAddressFile = async () => {
        const fdata = new FormData();
        fdata.append('addressfile', addressfile);
        fdata.append('addressfileBack', addressfileBack);
        fdata.append('key_id', key);
        const res = await axios.post('https://nuggettrap.com/api/ekyc/upload-file.php', fdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        setInterval(() => {
            setprogressno2((pre) => pre + 1)
        }, 150);
    }
    const uploadGovtId = async () => {
        const fdata = new FormData();
        fdata.append('govtfile', govtfile);
        fdata.append('govtfileBack', govtfileBack);
        fdata.append('key_id', key);
        const res = await axios.post('https://nuggettrap.com/api/ekyc/upload-file.php', fdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        setInterval(() => {
            setProgressNow((pre) => pre + 1)
        }, 150);
    }


    const handalCitys = (e) => {
        setCity(e)
        setLinkdata((prev) => ({ ...prev, city: e }))
    }
    const handalGetCountry = (e) => {
        setCountry(e)
        setLinkdata((prev) => ({ ...prev, country: e }))
    }
    const handalState = (e) => {
        setState(e)
        setLinkdata((prev) => ({ ...prev, state: e }))
    }
    const handalPassword = (e) => {
        console.log(e.target.value)
        console.log(linkData.password)
        if (linkData.password) {
            if (linkData.password === e.target.value) {
                console.log('same ')
                setpasserror(null)
            } else {
                console.log('not same ')
                setpasserror("Please enter same password");
            }
        }
    }
    const onChange = (value) => {
        console.log("Captcha value:", value);
    }
    const handalSubmit = (e) => {
        e.preventDefault();
        if (!linkData.country) return setError('Please enter country');
        if (!linkData.state) return setError('Please enter state');
        if (!linkData.zip) return setError('Please enter zip code');
        if (!linkData.password) return setError('Please enter Password.');
        setError(null);

        const data = new FormData()
        data.append('govt_id', govtfile);
        data.append('address_proof', addressfile);
        data.append('profile_img', profilePhoto);
        data.append('key', key);
        data.append('address', linkData.address);
        data.append('country', linkData.country);
        data.append('state', linkData.state);
        data.append('city', linkData.city);
        data.append('zip', linkData.zip);
        data.append('password', linkData.password);
        data.append('alldata', JSON.stringify(linkData));

        axios.post('https://nuggettrap.com/api/ekyc/save-data.php', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(() => {
                navigation('/')
            })

        // fetch('https://nuggettrap.com/api/ekyc/save-data.php',{
        //     method:'POST',
        //     body:data,
        //     headers:{
        //         'Content-type':'multipart/form-data'
        //     }
        // })

    }

    useEffect(() => {
        if (key) {
            fetch('https://nuggettrap.com/api/ekyc/get-data.php?id=' + key, {
                method: 'POST',
            })
                .then((res) => res.json())
                .then((result) => {
                    console.log(result);

                    if (!result.error) {
                        console.log(result.result[0])
                        if(result.result[0].draft ){
                            if(result.result[0]?.draft?.country){
                                setCountry(result.result[0].draft.country)
                            }
                            if(result.result[0].draft.state){
                                setState(result.result[0].draft.state)
                            }

                            setLinkdata(result.result[0].draft)
                        }else{
                            setLinkdata(result.result[0])
                        }
                        
                    } else {
                        navigation('/', { state: { message: `${key} Already applied` } });
                    }
                })
        }
    }, [])
    useEffect(()=>{
        if(linkData.country)
        setCountry(linkData.country)
        if(linkData.state)
        setState(linkData.state)

        if(linkData.city)
        setCity(linkData.state)

    },[linkData])
    return (
        <div className='reg-main'>
            <Row className='reg-cener container'>
                <Col sm={12} className='reg-box'>
                    <Row>
                        <Col md={6}>
                            <img src={kycImg} alt=''
                                style={{ width: '100%' }} />

                        </Col>
                        <Col md={6}>
                            <h2>Registration</h2>
                            <form method='post' className='reg-form' onSubmit={handalSubmit}
                                encType='multipart/form-data'>
                                {error && <Row>
                                    <Col md={12}>
                                        <Alert color="danger">{error}</Alert>
                                        <hr />
                                    </Col>
                                </Row>}
                                <Row>
                                <Col md={12}>
                                        <Alert color="success">Data saved as a draft</Alert>
                                        <hr />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <MdMail />
                                                </InputGroupText>
                                                <Input type='email' placeholder='Please enter email'
                                                    readOnly
                                                    value={linkData.email} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <FaUser />
                                                </InputGroupText>
                                                <Input type='text' placeholder='Enter your first name' readOnly
                                                    value={linkData.fname} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup >
                                            <InputGroup>
                                                <InputGroupText>
                                                    <MdAccountCircle />
                                                </InputGroupText>
                                                <Input type='text' className='dilab'
                                                    value={linkData.mname} placeholder='Middel name'
                                                    onChange={(e) => { setLinkdata((prev) => ({ ...prev, mname: e.target.value })) }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={4}>
                                        <FormGroup disabled={true}>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <MdAccountCircle />
                                                </InputGroupText>
                                                <Input type='text' className='dilab'
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={linkData.lname} />
                                            </InputGroup>
                                        </FormGroup>
                                        <PhoneInput
                                            country={'us'}
                                            style={{ width: '100%' }}
                                            value={linkData.phone}
                                            disabled={true}
                                        />
                                        {/* value={this.state.phone}
                                    onChange={phone => this.setState({ phone })} */}

                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <MdAddLocation />
                                                </InputGroupText>
                                                <Input name="text" type="textarea" placeholder='Address'
                                                    value={linkData.address}
                                                    onChange={(e) => { setLinkdata((prev) => ({ ...prev, address: e.target.value })) }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Country selectCountry={handalGetCountry} setCountry={country}  />
                                    </Col>
                                    <Col md={4}>
                                        <States selectState={handalState} selectCountryId={country} StateSet={state} />
                                    </Col>
                                    <Col md={4}>
                                        <Citys selectCity={handalCitys} selectedStated={state}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <FaMapPin />
                                                </InputGroupText>
                                                <Input type='text' placeholder='Please enter zip code'
                                                    value={linkData.zip}
                                                    onChange={(e) => { setLinkdata((prev) => ({ ...prev, zip: e.target.value })) }} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <FaEye />
                                                </InputGroupText>
                                                <Input type='password' placeholder='Enter password'
                                                    value={linkData.password}
                                                    onChange={(e) => { setLinkdata((prev) => ({ ...prev, password: e.target.value })) }} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <FaEye />
                                                </InputGroupText>
                                                <Input type='password' placeholder='Enter confirm password'
                                                    value={linkData.password2}
                                                    onChange={handalPassword} />
                                            </InputGroup>
                                            {passerr &&
                                                <p className='text-danger'>{passerr}</p>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        {/*  Citizen / Resident / Visa */}
                                        <FormGroup>
                                            <Label>Resident</Label>
                                            <Input type='select' value={linkData.resident}
                                                onChange={(e) => { setLinkdata((prev) => ({ ...prev, resident: e.target.value })) }}>
                                                <option value=''>Select</option>
                                                <option value='Citizen'>Citizen</option>
                                                <option value='Resident'>Resident</option>
                                                <option value='Visa'>Visa</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {linkData.resident &&
                                        <>
                                            {linkData.resident === 'Visa' &&
                                                <>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>Purpose</Label>
                                                            <Input type='select' value={linkData.purpose}
                                                                onChange={(e) => { setLinkdata((prev) => ({ ...prev, purpose: e.target.value })) }}>
                                                                <option value=''>Select</option>
                                                                <option value='work'>work</option>
                                                                <option value='school'>school</option>
                                                                <option value='religious'>religious</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>Exp. date</Label>
                                                            <DatePicker className='form-control'
                                                                selected={linkData.visa_exp}
                                                                onChange={(date) => { setLinkdata((prev) => ({ ...prev, visa_exp: date })) }} />
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            }
                                        </>
                                    }
                                </Row>
                                <Row>
                                    <Col md={12} className='row-a'>

                                        <Row>
                                            <Col md={4}>
                                                <Label>Govt. ID * (front side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setGovfile(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4}>
                                                <Label>Govt. ID * (Back side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setGovfileBack(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4} >
                                                <br />
                                                <Button type='button' color='secondary' block
                                                    onClick={uploadGovtId}
                                                >
                                                    Upload <MdFileUpload />
                                                </Button>
                                                <Progress animated
                                                    color="dark"
                                                    value={progressNow} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label>Address Proof: * (front side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setAddressfile(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4}>
                                                <Label>Address Proof * (Back side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setAddressfileBack(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4} >
                                                <br />
                                                <Button type='button' color='secondary' block
                                                    onClick={saveAddressFile}
                                                >
                                                    Upload <MdFileUpload />
                                                </Button>
                                                <Progress animated
                                                    color="dark"
                                                    value={progressno2} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label>Utility: * (front side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setProfilePhoto(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4}>
                                                <Label>Utility: * (Back side) </Label>
                                                <Input type='file'
                                                    onChange={(e) => { setProfilePhotoBack(e.target.files[0]) }} />
                                            </Col>
                                            <Col md={4} >
                                                <br />
                                                <Button type='button' color='secondary' block
                                                    onClick={saveUtility}>
                                                    Upload <MdFileUpload />
                                                </Button>
                                                <Progress animated
                                                    color="dark"
                                                    value={progressno3} />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Exp. date</Label>
                                            <DatePicker className='form-control'
                                                selected={linkData.godIDExp}
                                                onChange={(date) => { setLinkdata((prev) => ({ ...prev, godIDExp: date })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label> Exp. date</Label>
                                            <DatePicker
                                                className='form-control'
                                                selected={linkData.address_proof}
                                                onChange={(date) => { setLinkdata((prev) => ({ ...prev, address_proof: date })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Date of birth</Label>
                                            <DatePicker
                                                className='form-control'
                                                selected={linkData.dob}
                                                onChange={(date) => { setLinkdata((prev) => ({ ...prev, dob: date })) }} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <ReCAPTCHA
                                            sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                            theme='dark'
                                            onChange={onChange}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Button color='primary' block>Save
                                            <FaSave />
                                        </Button>
                                        <br />
                                        <Button type='button' block onClick={saveDraft}
                                        >Save as draft
                                            <MdSaveAs />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                                <br />
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Registrion