import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Table, Tooltip } from 'reactstrap'
import { MdFileOpen } from 'react-icons/md'
import { FaBan, FaCheckCircle, FaCheckDouble, FaFolderOpen } from 'react-icons/fa'
import { useNavigate, Link } from 'react-router-dom'
function UsersList() {
    const navigate = useNavigate()
    const [userList, setUserList] = useState([])

    const handalApprove = (e) =>{
        console.log(e)
        fetch('https://nuggettrap.com/api/ekyc/set-users.php',{
            method:'POST',
            body:JSON.stringify(e)
        })
        .then((res)=>res.json())
        .then((result)=>{
            fetch('https://nuggettrap.com/api/ekyc/get-users.php', {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((result) => {
                setUserList(result.result)
            })
        })

    }
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/get-users.php', {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((result) => {
                setUserList(result.result)
            })
    }, [])
    return (
        <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />

                <div style={{ display: 'block' }}>
                    <Card>
                        <CardHeader>
                            <h3>Customer's Data</h3>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Full Name</th>
                                        <th>Email </th>
                                        <th>Contact</th>

                                        <th>Govt id</th>
                                        <th>Address proof </th>
                                        <th>Video link</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.length > 0 &&
                                        <>
                                            {userList.map((user, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1} </td>
                                                    <td>{user.fname} </td>
                                                    <td>{user.email} </td>
                                                    <td>{user.phone} </td>

                                                    
                                                    <td><Link target='_blank' to={user.id_proof}>Govt id link </Link>  </td>
                                                    <td><Link to={user.adress_proof}>Address proof link </Link>  </td>
                                                    <td>
                                                        <Link to={`/admin/video-page/${user.key_data}`}> Video </Link>
                                                    </td>
                                                    <td>
                                                        <ButtonGroup>

                                                            <Button type='button' color='primary'
                                                                title='Show details'
                                                                size="sm"
                                                                onClick={() => { navigate('/admin/user/' + user.key_data) }}
                                                            ><FaFolderOpen />
                                                            </Button>
                                                            


                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </div>

        </div>
    )
}

export default UsersList