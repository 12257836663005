import React, { useEffect, useState } from 'react'
import loginimg from './login.jpg'
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { FaEye, FaLockOpen, FaUser } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

function AdminLogin() {
    const navigate = useNavigate();
    const [userData, setUserData]= useState({});
    const onChange = (value) => {
        console.log("Captcha value:", value);
    }
    const handalLogin = (e)=>{
        e.preventDefault();
        console.log(userData)
        if(userData.username &&  userData.password){
            if(userData.username =='' || userData.password==''){
                return alert('Please enter email and password')
            }
        }else{
            return alert('Please enter email and password')
        }

        
        fetch('https://nuggettrap.com/api/ekyc/login.php',{
            method:'POST',
            body:JSON.stringify(userData)
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(!result.error){
                var data = JSON.stringify(result.result);
                localStorage.setItem('userData', data);
                navigate('/admin/dashboard', {state:{userData: result.result} })
            }else{
                alert('Please enter valid email or password')
            }
        })
    }
    useEffect(()=>{

    },[])
  return (
    <div className='login-main'>
            <Row className='row-login'>
                <Col md={8}>
                    <img src={loginimg} style={{ width: '100%' }} alt='ekyc login' />
                </Col>
                <Col md={4} >
                    <div className='login-box'>
                        <h2 className='text-center'> Login</h2>

                        <form method='post' className='log-form' onSubmit={handalLogin}>
                            <FormGroup>
                                <Label>User name</Label>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaUser color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input placeholder='Enter Username' value={userData.username} 
                                    onChange={(e)=>{setUserData((prev)=>({...prev, username:e.target.value})) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaEye color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input type='password' placeholder='******' value={userData.password} 
                                    onChange={(e)=>{setUserData((prev)=>({...prev, password:e.target.value})) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            
                            <ReCAPTCHA
                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                theme='dark'
                                onChange={onChange}
                            />
                            <FormGroup>

                            </FormGroup>
                            <FormGroup>
                                <Button type='submit' color='primary' block>Login
                                    <FaLockOpen color='#fff' size={20} />
                                </Button>
                            </FormGroup>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
  )
}

export default AdminLogin