import React,{ useEffect, useState } from 'react';
import { FormGroup,Label,Input } from 'reactstrap';

function States(props) {
    const [stateList, setStateList ] = useState([]);
    const [selectState, setSelctState ] = useState('');

    
    useEffect(()=>{
        props.selectState(selectState)
    },[selectState])
    
    useEffect(()=>{
        if(stateList.length ==0 && props.selectCountryId){
            fetch(`https://nuggettrap.com/api/crm/state-list.php?countryid=${props.selectCountryId}`)
            .then((res)=>res.json())
            .then((result)=>{
                setStateList(result.result)
            })
        }
    },[props.selectCountryId])
  return (
    <div>
      <FormGroup>
            <Label>State</Label>
            <Input name="state" type="select" onChange={(e)=>{setSelctState(e.target.value)}}>
                <option>Select state</option>
                {stateList.length>0 &&
                <>
                {stateList.map((state)=>(
                    <option key={state.id} value={state.id}
                    selected={state.id===props.StateSet}
                    >{state.name}</option>
                ))}
                </>
                 }
            </Input>
        </FormGroup>
    </div>
  )
}

export default States
