import React, { useState, useEffect } from 'react'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { useParams } from 'react-router-dom'

function VideoDetails() {
    const { id } = useParams();
    const [profileData, setProfileData] = useState([]);
    const [qusList, setQusList] = useState([])

    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/user-data.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setProfileData(result.result[0])
                console.log(result.result[0])
            })
    }, [])
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/get-qus-list.php')
            .then((rs) => rs.json())
            .then((resul) => {
                setQusList(resul.result);
            })
    }, [])
    return (
        <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />
                <div style={{ display: 'block' }}>
                    <Card>
                        <CardHeader><h3>Video </h3> </CardHeader>
                        <CardBody>
                            <Row>
                                {profileData.video_link &&

                                    <Col md={6}>
                                        <video width="100%" autoplay controls>
                                            <source src={profileData.video_link} type="video/mp4" />
                                            <source src={profileData.video_link} type="video/ogg" />
                                        </video>
                                    </Col>
                                }
                                <Col md={6}>
                                    <h2>Question list</h2>
                                    <ListGroup>
                                        <ListGroupItem  >I am {profileData.fname}  {profileData.lname}, Applied for ekyc with Smartcard marketing
                                            systems Inc.
                                        </ListGroupItem>
                                        <ListGroupItem  >
                                            My verification code is {profileData.key_data}
                                        </ListGroupItem>
                                        <ListGroupItem  >
                                            Show your id proof.
                                        </ListGroupItem>
                                        <ListGroupItem  >
                                            Show your address proof.
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default VideoDetails