import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Button, Card, CardBody, CardHeader, Row, Col, InputGroup, Input } from 'reactstrap'
import { MdFileOpen } from 'react-icons/md'
import { } from 'react-icons/fa'
import { useParams, useNavigate } from 'react-router-dom'
import { BsCheck2All, BsPersonX } from 'react-icons/bs'
import { TbH3 } from 'react-icons/tb'

function UserDetails() {
    const { id } = useParams();
    const navigate = useNavigate()

    const [userData, setUserData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [userIdproofDetails, setuserIdproofDetails] = useState([]);
    const [note, setnote] = useState('')
    const sendVideoLink = () => {
        fetch('https://nuggettrap.com/api/ekyc/send-video.php', {
            method: 'POST',
            body: JSON.stringify(profileData)
        })

    }
    const handalApprove = (typed) => {
        console.log(typed)
        if (typed == 'reject') {
            if (note == '') { return alert('Please add note for reject') }
            var postData = { email: profileData.email, id: profileData.key_data, status: 2, note: note };
        }
        if (typed == 'approved') {
            var postData = { email: profileData.email, id: profileData.key_data, status: 1 };
        }

        fetch('https://nuggettrap.com/api/ekyc/set-users.php', {
            method: 'POST',
            body: JSON.stringify(postData)
        })
            .then((res) => res.json())
            .then(() => {
                fetch('https://nuggettrap.com/api/ekyc/user-data.php?id=' + id)
                    .then((res) => res.json())
                    .then((result) => {

                        setProfileData(result.result[0])
                        setuserIdproofDetails(result.id_proofs)
                    })
            })
    }
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/user-data.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setProfileData(result.result[0])
                setuserIdproofDetails(result.id_proofs)
            })
    }, [])
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/get-user-details.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setUserData(result.result)
            })
    }, [])
    return (
        <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />
                <div style={{ display: 'block' }}>
                    <Card>
                        <CardHeader>
                            <h3>User Details</h3>
                        </CardHeader>
                        {profileData &&
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <p>First name:<b>  {profileData.fname} </b></p>
                                        <p>Last name:<b>  {profileData.lname} </b></p>
                                    </Col>
                                    <Col md={4}>
                                        <p>Contact:<b>  +{profileData.phone} </b></p>
                                        <p>Email: <b> {profileData.email} </b></p>
                                    </Col>
                                    <Col md={4}>
                                        <p>Date of birth:<b>  {profileData.dob} </b></p>
                                    </Col>
                                </Row>
                                {profileData.log_details &&
                                <Row>
                                    <Col md={4}>
                                        <p>Country :<b>  {profileData.log_details.data.country_name} </b></p>
                                        <p>Country Capital:<b>  {profileData.log_details.data.country_capital} </b></p>
                                    </Col>
                                    <Col md={4}>
                                        <p>IP address :<b>  {profileData.log_details.data.ip} </b></p>
                                        <p>Latitude - longitude :<b>  {profileData.log_details.data.latitude} {profileData.log_details.data.longitude} </b></p>
                                    </Col>
                                    <Col md={4}>
                                        <p>Region :<b>  {profileData.log_details.data.region} </b></p>
                                        <p>Region code:<b>  {profileData.log_details.data.region_code} </b></p>
                                    </Col>
                                </Row>
                                }
                                <br /><br />
                                <Row>
                                    <Col md={6} style={{background:'#dddddd9e'}}>
                                        <h5>Address proof <i className='date-file'>Exp. date {profileData.adress_prf_exp}</i> </h5>
                                        <hr />
                                        <img src={(profileData.adress_proof)} alt='' className='photo-img' />
                                        <p className='text-justify'>{profileData.address_proof_text} </p>
                                        <hr />
                                    </Col>
                                    <Col md={6} style={{background:'#dddddd9e'}}>
                                        <h5>Uploaded video</h5>
                                        <hr />
                                        {profileData.video_link &&
                                            <video width="320" height="240" autoplay controls>
                                                <source src={profileData.video_link} type="video/mp4" />
                                                <source src={profileData.video_link} type="video/ogg" />
                                            </video>
                                        }
                                        {!profileData.video_link &&
                                            <div className='na-video'>
                                                <h3 className='text-center'>No video </h3>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    {userIdproofDetails.length > 0 &&
                                        <>
                                            {userIdproofDetails.map((list, i) => (
                                                <Col md={6} style={{background:'#dddddd9e'}}>
                                                    <h5 className='filh'>{list.id_proof_file_type.replace("_", " ")} <i className='date-file'>Exp. date {list.id_proof_exp_date}</i> </h5>
                                                    <hr />
                                                    <Row>
                                                        <Col md={6}>
                                                            <img src={list.id_proof_front} alt='' className='photo-img' style={{width:'100%'}} />
                                                        </Col>
                                                        <Col md={6}>
                                                            <img src={list.id_proof_back} alt='' className='photo-img' style={{width:'100%'}} />
                                                        </Col>
                                                    </Row>
                                                    {/* <p className='text-justify'>{profileData.address_proof_text} </p> */}
                                                    <hr />
                                                </Col>
                                            ))}
                                        </>
                                    }


                                </Row>
                                <Row>
                                    <hr />
                                    {profileData.status == '2' ? (
                                        <Col md={6}>
                                            <Button color='danger'>Rejected</Button>
                                        </Col>
                                    ) : (
                                        <Col md={6}>
                                            <InputGroup >
                                                <Input placeholder='Reason reject!' value={note}
                                                    onChange={(e) => { setnote(e.target.value) }} />
                                                <Button color='danger' onClick={() => { handalApprove('reject') }}>
                                                    Reject <BsPersonX />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    )}
                                    {profileData.status == '1' ? (
                                        <Col md={6}>
                                            <Button color='success' >
                                                Approved
                                                <BsCheck2All />
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col md={6}>
                                            <Button color='success' onClick={() => { handalApprove('approved') }}>
                                                Approve
                                                <BsCheck2All />
                                            </Button>
                                        </Col>
                                    )}


                                </Row>
                            </CardBody>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default UserDetails