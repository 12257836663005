import React from 'react'
import { MdMessage, MdNotifications } from 'react-icons/md'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <nav class="navbar navbar-light navbar-expand-md bg-dark justify-content-md-center justify-content-start">
            <Link class="navbar-brand d-md-none d-inline" to="/">Brand</Link>
            <button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="nav-link" href="#_"><i class="fa fa-search mr-1"></i></a>
            <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                <ul class="navbar-nav mx-auto text-md-center text-left">
                    <li class="nav-item">
                        <Link class="nav-link" to="/">Home</Link>
                    </li>
                    
                </ul>
                <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                    <li class="nav-item">
                        <MdMessage />
                    </li>
                    <li class="nav-item">
                        <MdNotifications />
                    </li>
                </ul>
            </div>
        </nav>

    )
}

export default Header