import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login';
import Registrion from './Registrion';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './app/admin/Dashboard';
import UsersList from './app/admin/UsersList';
import AdminLogin from './AdminLogin';
import UserDetails from './app/admin/UserDetails';
import VideoRecorder from './app/admin/VideoRecorder';
import QuestionMaster from './app/admin/QuestionMaster';
import VideoDetails from './app/admin/VideoDetails';
import PendingUserList from './app/admin/PendingUserList';



function App() {
  return (
    <div>
      <Routes>
        <Route path='/' >
          <Route path='/' element={<Login /> }></Route>
          <Route path='/login' element={<AdminLogin /> }></Route>
          <Route path='/registration/:key' element={<Registrion /> }></Route>
        </Route>
        <Route path='/admin'>
          <Route path='/admin/dashboard' element={<Dashboard /> }></Route>
          <Route path='/admin/users' element={<UsersList /> }></Route>
          <Route path='/admin/users-pending' element={<PendingUserList /> }></Route>
          <Route path='/admin/user/:id' element={<UserDetails /> }></Route>
          <Route path='/admin/video' element={<VideoRecorder /> }></Route>
          <Route path='/admin/video-page/:id' element={<VideoDetails /> }></Route>
          <Route path='/admin/question-master' element={<QuestionMaster /> }></Route>
        </Route>
      </Routes>
      {/* <Login /> */}
    </div>
  );
}

export default App;
