import React, { useState, useRef } from 'react';
import { AiOutlineUser, AiOutlineVideoCamera, AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { BsCameraFill } from 'react-icons/bs';
import Webcam from 'react-webcam';
import { Button, ButtonGroup, Label } from 'reactstrap';

const PhotoUploadOrCapture = (props) => {
  const [image, setImage] = useState(null);
  const [showCam, setshowCam] = useState(false);
  const handalPhoro = () => { setshowCam(true) }

  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);


  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imgURL = URL.createObjectURL(file);
      setImage(imgURL);
      props.getPhoto(file)
      // uploadImage(file); // Upload the file directly
    }

  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const blob = dataURItoBlob(imageSrc);
      const fileName = `captured_${Date.now()}.png`; // Set file name with extension
      const file = new File([blob], fileName, { type: 'image/png' });
      setImage(imageSrc);
      props.getPhoto(file);
    }
  };

  return (
    <div>
      <div>
        <input
        ref={fileInputRef}
          type="file"
          accept="image/*" onChange={handleFileChange}
          style={{ display: 'none' }} // Hide the file input
        />
        <ButtonGroup>
          <Button color="primary" onClick={handleButtonClick}>
            Upload Profile photo 
            <AiOutlineUser size={25} />
            
          </Button>
          <Button onClick={handalPhoro} color="warning"> Open camera 
            <AiOutlineVideoCameraAdd size={25}/>
          </Button>
        </ButtonGroup>

      </div>

      {showCam &&
        <div style={{ margin: '20px 0' }}>
          {/* {image && <img src={image} alt="Uploaded or Captured" style={{ width: '300px', marginTop: '20px' }} />} */}
          {image ? (
            <>
              <img src={image} alt="Uploaded or Captured" style={{ width: '300px' }} />
            </>
          ) : (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/png"
                width={300}
              />
            </>
          )}
          <ButtonGroup>
            <Button color='dark' type='button' onClick={capturePhoto}>Photo
              <BsCameraFill />
            </Button>
            <Button color='danger' type='button' onClick={(e) => { setImage(null) }}>Cancel </Button>
          </ButtonGroup>
        </div>
      }
    </div>
  );
};

export default PhotoUploadOrCapture;
