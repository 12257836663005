import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';


function Dashboard() {
  const [userData, setUserData] = useState({});
  const navogate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('userData')
    if (user) {
      setUserData(JSON.parse(user));
    }
  }, [])
  return (
    <div className='main-div'>
      <AdminSidebar />
      <div className='fill-width'>
        <Header />
        <div>
          <div className='demo-details'>
            <Card>

              <CardBody style={{ padding: '0px', margin: '0px' }}>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='profile-left'>
                      <div className='photo-user'>
                        <img src={userData.profile_photo} alt='' />
                      </div>
                      <div className='left-data'>
                        <h3>{userData.fname}</h3>
                        {/* <Button color="light">
                        <FaEdit size={10}  />
                        </Button> */}

                      </div>
                    </div>
                  </div>
                  <div className='col-sm-8'>
                    <div>
                      <h4 className='infor-text'>Information</h4>
                    </div>
                    <hr />
                    <div className='infor-data'>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <h5>First name</h5>
                          <p>{userData.fname} </p>
                        </div>
                        <div className='col-sm-6'>
                          <h5>Last name</h5>
                          <p>{userData.lname} </p>
                        </div>
                      </div>
                      {userData.lavel != 1 &&
                        <>
                          <div className='row'>
                            <div className='col-sm-6'>
                              <h5>Email</h5>
                              <p>{userData.email} </p>
                            </div>
                            <div className='col-sm-6'>
                              <h5>Phone</h5>
                              <p>+{userData.phone} </p>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-6'>
                              <h5>Date of birth</h5>
                              <p>{userData.dob} </p>
                            </div>
                            
                            <div className='col-sm-12'>
                              <Button color='danger' block
                                onClick={() => { navogate('/admin/video') }}>Video Interview Link</Button>
                            </div>
                          </div>
                        </>
                      }

                    </div>
                  </div>
                </div>
                <div>

                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard