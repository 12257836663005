import React,{ useEffect, useState } from 'react';
import { FormGroup,Label,Input } from 'reactstrap';


function Country(props) {
    const [counteryList, setCounList ] = useState([]);
    const [selectCountry, setSelctCountry ] = useState('');

    useEffect(()=>{
        props.selectCountry( selectCountry)
    },[selectCountry])
    useEffect(()=>{
        if(counteryList.length ==0){
            fetch('https://nuggettrap.com/api/crm/country-list.php')
            .then((res)=>res.json())
            .then((result)=>{
                setCounList(result.result)
            })
        }
    },[])
  return (
    <div>
        <FormGroup>
            <Label>Country</Label>
            <Input name="country" type="select" onChange={(e)=>{setSelctCountry(e.target.value)}}>
                <option>Select country</option>
                {counteryList.length>0 &&
                <>
                {counteryList.map((country)=>(
                    <option key={country.id} 
                    selected={country.id===props.setCountry}
                    value={country.id}>{country.name}</option>
                ))}
                </>
                 }
            </Input>
        </FormGroup>
    </div>
  )
}

export default Country
