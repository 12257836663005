import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Card, CardBody, CardHeader, Col, Row, Input, FormGroup, Label, Button, Table } from 'reactstrap'
import { MdDelete } from 'react-icons/md'


function QuestionMaster() {
    const [qus, setQus]= useState('');
    const [qusList, setQusList] = useState([])
    const saveQus = (e)=>{
        fetch('https://nuggettrap.com/api/ekyc/save-qus.php',{
            method:'POST',
            body:JSON.stringify(qus)
        })
        .then((res)=>res.json())
        .then((result)=>{
            var list = qusList;
            list.push(qus)
            setQusList(list)
            setQus('')
        })
    }
    useEffect(()=>{
        fetch('https://nuggettrap.com/api/ekyc/get-qus-list.php')
        .then((rs)=>rs.json())
        .then((resul)=>{
            setQusList(resul.result);
        })
    },[qusList])
    return (
        <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />
                <div style={{ display: 'block' }}>
                    <div className='container' style={{paddingTop: '60px'}}>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardHeader><h4> Question</h4> </CardHeader>
                                    <CardBody>
                                        <FormGroup>
                                            <Label>Add question</Label>
                                            <Input type='textarea' value={qus}
                                            onChange={(e)=>{setQus(e.target.value)}} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button type='button' color='info' 
                                            onClick={saveQus}
                                            block>Add</Button>
                                        </FormGroup>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Table>
                                    <thead>
                                        <tr className="table-dark">
                                            <th>id</th>
                                            <th>Question</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qusList.length >0 &&
                                        <>
                                        {qusList.map((qus,i)=>(
                                            <tr key={i}>
                                                <td>{i+1} </td>
                                                <td>{qus.qus} </td>
                                                <td>
                                                    <Button color='danger'>
                                                        <MdDelete />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )) }
                                        </>
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionMaster